import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SourceService {
  //urll="http://localhost/dentalDoctor";
  urll="https://dentaldoctor.biz/dentalDoctor";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
   })
  };
 
  constructor(private http: HttpClient) { }

  bookAppointment(data){
    
    return  this.http.post(this.urll+'/bookAppointment.php', data,this.httpOptions);
     
}
verifyAppointment(aId:any){
  return this.http.get(this.urll+'/verifyAppointment.php?id='+aId);
}

sendOTP(mob,otp){
    
  return this.http.get('https://api.msg91.com/api/v5/otp?authkey=267374A791umImdfo61d165f3P1&template_id=61d482392431f732663e1075&extra_param={"COMPANY_NAME":"Dental Doctor","OTP":"'+otp+'"}&mobile='+mob);
}

getUser(mob){
 
  return this.http.get(this.urll+'/login.php?mob='+mob);
}

getAppointments(mob,query,apId){
 
  let admin=this.adminStatus();
  console.log(this.urll+'/getAppointments.php?mob='+mob+'&admin='+admin+'&query='+query+'&apId='+apId);
  return this.http.get(this.urll+'/getAppointments.php?mob='+mob+'&admin='+admin+'&query='+query+'&apId='+apId);
}

getAppointmentStatus(id){
  
  return this.http.get(this.urll+'/getAppointmentStatus.php?id='+id);
}

updateAppointment(data:any){
    console.log(data);
  return  this.http.post(this.urll+'/updateAppointment.php', data,this.httpOptions);
   
}

updatePayment(data:any){
    
  return  this.http.post(this.urll+'/updatePaymentId.php', data,this.httpOptions);
   
}

getPage(page){
  
  return this.http.get(this.urll+'/pages/'+page+'.php',{ responseType: 'text' });
}

loginStatus(){
        
  if(localStorage.getItem("userId")){
      
      return localStorage.getItem("userId");
  }else{
       return false;
  }
}
adminStatus(){
        
  if(localStorage.getItem("userId")){
    let user:any= JSON.parse(localStorage.getItem("user"));
    if(user.role=='admin'){
      return true;
    }else{return false;}
  }else{
       return false;
  }
}

}
