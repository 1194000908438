import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from "./admin.component";
import { MyAppointmentsComponent } from "./my-appointments/my-appointments.component";
import { AppointmentsComponent } from "./appointments/appointments.component";
const routes: Routes = [ {
  path: '',
  component: AdminComponent,
  children: [
    { path: '', redirectTo: 'my-appointments',pathMatch: 'full' },
    { path: 'my-appointments', component: MyAppointmentsComponent },
    { path: 'my-appointments/:apId', component: MyAppointmentsComponent },
    { path: 'my-appointments/:apId/:verify', component: MyAppointmentsComponent },
    { path: 'appointments', component: AppointmentsComponent },
    { path: 'appointments/:status', component: AppointmentsComponent },
  ]
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
