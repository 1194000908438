import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';

import { AdminComponent } from "./admin.component";
import { MyAppointmentsComponent } from "./my-appointments/my-appointments.component";
import { AppointmentsComponent } from "./appointments/appointments.component";

@NgModule({
  declarations: [AdminComponent,MyAppointmentsComponent,AppointmentsComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,FormsModule,ReactiveFormsModule
  ],
  exports: [MyAppointmentsComponent,AppointmentsComponent], schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule { }
