import { Component, OnInit } from '@angular/core';
import { DatePipe} from '@angular/common';
import { SourceService } from '../../source.service';
import { FormBuilder} from '@angular/forms';
import {Router,ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit {
  appointments:any=[];loading=0;isModalOpen=false;statuses;admin=false;statusCModal=false;statusForm;selectedAppointmentId;filterForm:any;date;treatment='Root Canal';stats;selectedAppointment:any;
  paymentModal:any=[];
  constructor(private source: SourceService,private formBuilder: FormBuilder,private route: ActivatedRoute) {
    let status = this.route.snapshot.paramMap.get("status");
    
    this.statusForm = this.formBuilder.group({
      appointmentId: [''],
      statu: [''],
      msg: [''],
      price: [''],
      date: [''],
      time: [''],
      doctor: [''],
      place: [''],
      });

      this.filterForm = this.formBuilder.group({
        date: [""],
        treatment: [" AND `disease` LIKE '%%'"],
        location: [" AND `location` LIKE '%%'"],
        payment: [" AND `payment_id` LIKE '%%'"],
        schedule: [" AND `appointmentTime` LIKE '%%'"]
        });

        this.filterChange();
   }
   
   

   filterChange(){
   
  
    let v =this.filterForm.value;

    var datePipe = new DatePipe('en-US');
    let dt= datePipe.transform(v.date, 'dd-MM-yy');

    let dat='';
    if(v.date){ dat="`date`='"+dt+"'";}else{dat="`date` LIKE '%%'"}
    let q=dat+''+v.treatment+''+v.location+''+v.payment+''+v.schedule;
    console.log(q);
    console.log(encodeURIComponent(q));
    this.getAppointments(encodeURIComponent(q));
  
   }

   getAppointments(query){
    this.loading=1;
    var id=localStorage.getItem("userId");
    this.source.getAppointments(id,query,null)
  .subscribe(data => {
    this.loading=0;
       console.log(data);
        this.appointments=data;
   });
   
}

setOpen(isOpen: boolean,appointment) {
  this.isModalOpen = isOpen;
 
 this.source.getAppointmentStatus(appointment.id)
  .subscribe(data => {
    this.statuses=data;
    console.log(data);

        
   });
}
didDismiss(){
  this.filterChange();
}

statusChange(isOpen: boolean,appointment) {
  this.statusCModal = isOpen;
  this.selectedAppointmentId=appointment.id;
  this.selectedAppointment=appointment;
  console.log(appointment);
}

updateAppointment(data){
  console.log(data);
  data.email=this.selectedAppointment.email;
  data.mobile=this.selectedAppointment.mobile;
  data.msg=data.statu+' : '+data.msg;
  console.log(data);

  this.source.updateAppointment(data)
  .subscribe(data => {
   
      this.statusForm.reset();
      this.statusCModal=false;
        
   });
}

  ngOnInit() {}

}
